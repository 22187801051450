import 'normalize.css/normalize.css';
import 'bootstrap-4-grid/css/grid.min.css';
import './scss/main.scss';
import axios from 'axios';
import serialize from 'form-serialize';




const pageButtons = document.querySelectorAll('[data-page-target]');
for (let pageButton of pageButtons) {
  pageButton.addEventListener('click', (e) => {
    e.preventDefault();
    const target = pageButton.getAttribute('data-page-target');
    const targetEl = document.querySelector(`#${target}`);
    const currentPage = document.querySelector('.page__content.js-active');
    currentPage.classList.remove('js-active');
    targetEl.classList.add('js-active');
    window.scrollTo({
      top: 120,
      behavior: 'smooth'
    });
  });
}

const instructions = document.getElementById('instructions');
const instructionsButton = document.getElementsByClassName('instructions-button');
for (let i = 0; i < instructionsButton.length; i++) {
  instructionsButton[i].addEventListener('click',() => {
    instructions.classList.add('show-instructions');
    window.scrollTo({
      top: 120,
      behavior: 'smooth'
    });
  });
}
const instructionsClose = document.getElementById('instructions-close');
instructionsClose.addEventListener('click', () => {
  instructions.classList.remove('show-instructions');
});

const selectionValidation = (element) => {
  const question = document.getElementsByName(element);
  for (let i = 0; i < question.length; i++) {
    if (question[i].checked) {
      return i;
    }
  }
}
const trimField = (str) => {
  return str.replace(/^\s+|\s+$/g,'');
}

const formValid = () => {

  const data = new FormData(form);

  const submitButtons = form.querySelectorAll('button');
  for (const button of submitButtons) {
    button.setAttribute('disabled', true);
  }    

  axios
    .post('/home/save', data, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    })
    .then((res) => {
      for (const button of submitButtons) {
        button.removeAttribute('disabled');
      }

      if (res.data.finished) {
        window.location.href = '/Home/Finished';
      } else {
        const errors = document.querySelectorAll('.page__error-message');
        for (const error of errors) {
          error.classList.add('js-visible');
        }

        const currentPage = document.querySelector(
          '.page__content.js-active'
        );
        const nextPage = currentPage.nextElementSibling;
        if (nextPage && nextPage.id !== 'finished') {
          currentPage.classList.remove('js-active');
          nextPage.classList.add('js-active');
          window.scrollTo({
            top: 120,
            behavior: 'smooth'
          });
        }
      }
    })
    .catch((e) => {
      console.log(e);
      for (const button of submitButtons) {
        button.removeAttribute('disabled');
      }
    });

}

const form = document.querySelector('#QuestionForm');
if (form) {
  form.addEventListener('submit', (e) => {
    e.preventDefault();

    const alertMessage = "You can only select an answer once. Please rank in order of appropriateness.";  
    const rationaleMessage = "Please enter the rationale for this rating.";  

    const serialisedData = serialize(form, {
      hash: true,
      empty: true
    });

    let complete = true;
    for (const field in serialisedData) {
      if (serialisedData[field] === '') {
        complete = false;
      }
    }

    const completeInput = document.querySelector('#finished');
    completeInput.value = complete;

    if(document.getElementById('page-1').classList.contains('js-active')) {
      if (selectionValidation('Question-1') !== selectionValidation('Question-2') && selectionValidation('Question-1') !== selectionValidation('Question-3') && selectionValidation('Question-1') !== selectionValidation('Question-4') && selectionValidation('Question-2') !== selectionValidation('Question-3') && selectionValidation('Question-2') !== selectionValidation('Question-4') && selectionValidation('Question-3') !== selectionValidation('Question-4')) {
        if (trimField(document.getElementById('Rationale-1').value) == ''){
          alert(rationaleMessage);
        } else {
          formValid();
        }        
      } else {
        alert(alertMessage);
      }
           
    }

    if(document.getElementById('page-2').classList.contains('js-active')) {
      if (selectionValidation('Question-5') !== selectionValidation('Question-6') && selectionValidation('Question-5') !== selectionValidation('Question-7') && selectionValidation('Question-5') !== selectionValidation('Question-8') && selectionValidation('Question-6') !== selectionValidation('Question-7') && selectionValidation('Question-6') !== selectionValidation('Question-8') && selectionValidation('Question-7') !== selectionValidation('Question-8')) {
        if (trimField(document.getElementById('Rationale-2').value) == ''){
          alert(rationaleMessage);
        } else {
          formValid();
        } 
      } else {
        alert(alertMessage);
      }
    }  
    
    if(document.getElementById('page-3').classList.contains('js-active')) {
      if (selectionValidation('Question-9') !== selectionValidation('Question-10') && selectionValidation('Question-9') !== selectionValidation('Question-11') && selectionValidation('Question-9') !== selectionValidation('Question-12') && selectionValidation('Question-10') !== selectionValidation('Question-11') && selectionValidation('Question-10') !== selectionValidation('Question-12') && selectionValidation('Question-11') !== selectionValidation('Question-12')) {
        if (trimField(document.getElementById('Rationale-3').value) == ''){
          alert(rationaleMessage);
        } else {
          formValid();
        } 
      } else {
        alert(alertMessage);
      }
    }  
    
    if(document.getElementById('page-4').classList.contains('js-active')) {
      if (selectionValidation('Question-13') !== selectionValidation('Question-14') && selectionValidation('Question-13') !== selectionValidation('Question-15') && selectionValidation('Question-13') !== selectionValidation('Question-16') && selectionValidation('Question-14') !== selectionValidation('Question-15') && selectionValidation('Question-14') !== selectionValidation('Question-16') && selectionValidation('Question-15') !== selectionValidation('Question-16')) {
        if (trimField(document.getElementById('Rationale-4').value) == ''){
          alert(rationaleMessage);
        } else {
          formValid();
        } 
      } else {
        alert(alertMessage);
      }
    }      

  });
}
